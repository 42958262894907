import { uploadEmployeeCsvFileApi } from "@/api/services/main/employee";
import { ConfirmLeavePage } from "@/components/commons/ConfirmLeavePage";
import { Button } from "@/components/elements/Button";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Modal } from "@/components/elements/Modal";
import { Select } from "@/components/elements/Select";
import { useVisible } from "@/hooks/useVisible";
import { divider, background, text, state } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { MIME_TYPES } from "@/constants/file";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { employeeImportCsvSchema } from "@/features/employee/schema/employeeImportCsvSchema";
import { TEmployeeImportCsvForm } from "@/features/employee/types/employeeImportCsvForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { lazyApi } from "@/utils/api";
import {
  characterCodeOptions,
  delimiterOptions,
  ECharacterCode,
  EDataFormatImport,
  dataFormatImportOptions,
  EDelimiter,
  EFirstRowSpecification,
  firstRowSpecificationOptions,
} from "@/features/employee/constants/employeeExportCsv";
import { DropZone } from "@/components/elements/DropZone";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "@/utils/toast";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { TExtendFile } from "@/types/file";
import { showError } from "@/utils/error";
import { TApiError } from "@/types/api";

type TErrorItem = {
  message: string;
};

export const EmployeeImportCsvButton = () => {
  const importCsvModal = useVisible();
  const methods = useForm<TEmployeeImportCsvForm>({
    resolver: yupResolver(employeeImportCsvSchema),
    mode: "onBlur",
    defaultValues: {
      dataFormat: EDataFormatImport.IROHANA_STANDARD,
      firstRowSpecification: EFirstRowSpecification.JAPANESE_NAME,
      characterCode: ECharacterCode.AUTO_DETECT,
      delimiter: EDelimiter.COMMA,
    },
  });
  const { control, setValue } = methods;
  const [errorList, setErrorList] = useState<string[]>([]);
  const [importController, setImportController] = useState<AbortController | null>(null);
  const [importing, setImporting] = useState(false);

  const csvFile = useWatch({ control, name: "file" });

  const queryClient = useQueryClient();

  useEffect(() => {
    setErrorList([]);
  }, [csvFile]);

  const convertToFile = (input: TExtendFile | TExtendFile[] | null): File | null => {
    if (input === null) {
      return null;
    }

    if (Array.isArray(input)) {
      return input.length > 0 ? input[0] : null;
    }

    return input;
  };

  const onSubmit = async (payload: TEmployeeImportCsvForm) => {
    try {
      setImporting(true);
      const formData = new FormData();
      if (csvFile) {
        formData.append("file", csvFile);
      }
      formData.append("dataFormat", payload.dataFormat);
      formData.append("firstRowSpecification", payload.firstRowSpecification);
      formData.append("delimiter", payload.delimiter);
      formData.append("characterCode", payload.characterCode);

      const [controller, call] = lazyApi(() => uploadEmployeeCsvFileApi(formData));
      debugger;
      setImportController(controller);
      await call();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_LIST] });
      importCsvModal.close();
      toast.success(API_SUCCESS_MESSAGE);
    } catch (errorContainer) {
      const error = errorContainer as TApiError;
      if (error.code === "UNKNOWN_ERROR") {
        showError(error);
      } else {
        if (typeof errorContainer === "object" && errorContainer !== null) {
          if ("error" in errorContainer) {
            const listErrors = (errorContainer as { error: unknown }).error;
            if (Array.isArray(listErrors)) {
              setErrorList(listErrors.map((item: TErrorItem) => item.message));
            }
          } else if ("message" in errorContainer) {
            setErrorList([(errorContainer as { message: unknown }).message as string]);
          }
        } else {
          setErrorList(["Unknown error"]);
        }
      }
    } finally {
      setImportController(null);
      setImporting(false);
    }
  };

  const handleCancelImportFile = () => {
    if (!importController) return;
    importController.abort();
  };

  const downloadCsvExample = () => {
    window.open("/files/csv/csvEmployeeImportExampleShiftJIS.csv?v=1.0.0", "_blank");
  };

  return (
    <>
      <Button variant="outline" size="sm" onClick={() => importCsvModal.open()}>
        CSVインポート
      </Button>

      <Modal width={760} title="CSVインポート" isOpen={importCsvModal.visible} enableFooter={false} onClose={importCsvModal.close}>
        <Typography sx={{ my: "16px" }} component="h2" variant="body14">
          CSVファイルから特定技能外国人を登録できます。
        </Typography>
        <Box sx={{ border: "1px solid #CACDCD", padding: "24px", borderRadius: "8px" }}>
          <Form methods={methods} onSubmit={onSubmit} schema={employeeImportCsvSchema}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormField control={control} name="file" label="">
                  <Box mt={2} p={3} border="1px solid" borderColor={divider.high} borderRadius={rounded.sm}>
                    <DropZone options={{ multiple: false }} rules={{ acceptMimeTypes: [...MIME_TYPES.CSV] }} hideAfterUpload={true}>
                      <Typography variant="body14" whiteSpace="pre-wrap" textAlign="center">{`CSVファイルを\nドラッグ&ドロップ`}</Typography>
                    </DropZone>
                  </Box>
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="dataFormat">
                  <Select options={dataFormatImportOptions} />
                </FormField>
                <Typography
                  onClick={downloadCsvExample}
                  color={state.link_1}
                  sx={{ textDecoration: "underline", cursor: "pointer", whiteSpace: "nowrap" }}
                  variant="link14Semi"
                >
                  irohana標準形式のCSVテンプレートをダウンロード
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="firstRowSpecification">
                  <Select options={firstRowSpecificationOptions} />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="characterCode">
                  <Select options={characterCodeOptions.filter((item) => item.value == ECharacterCode.AUTO_DETECT)} />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="delimiter">
                  <Select options={delimiterOptions} />
                </FormField>
              </Grid>
            </Grid>
            {errorList.length > 0 && (
              <Stack
                sx={{ mt: "32px", p: "8px", background: background.error, color: text.error, maxHeight: "160px", overflowY: "auto" }}
                borderRadius={rounded.xs}
              >
                {errorList.map((item, index) => (
                  <Typography key={index} component="p" variant="cap12">
                    {item}
                  </Typography>
                ))}
              </Stack>
            )}
            <Stack sx={{ mt: "32px" }} flexDirection="row" justifyContent="space-between">
              <Button variant="outline" onClick={() => methods.reset()}>
                リセット
              </Button>
              <Button type="submit" disabled={!csvFile || importing} isLoading={importing}>
                取り込み
              </Button>
            </Stack>
          </Form>
        </Box>
      </Modal>

      <ConfirmLeavePage
        shouldBlock={Boolean(importController)}
        callBackLeave={() => {
          handleCancelImportFile();
        }}
      />
    </>
  );
};
